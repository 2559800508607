import { WritableSignal } from '@angular/core';

export enum PattyMenu {
  MainNavigation = 401,
  Casino = 405,
  LiveCasino = 406,
  Virtual = 407,
  CrashGames = 408,
  Sport,
}

export interface IMenuItem {
  badge?: string;
  disabled?: boolean;
  expanded?: boolean;
  fragment?: string;
  icon?: string;
  id?: number;
  items?: IMenuItem[];
  label?: string;
  order?: number;
  routerLink?: any;
  target?: string;
  title?: string;
  url?: string;
  separator?: boolean;
  slug?: string;
  queryParams?: {
    [k: string]: any;
  };
  className?: string;
  command?: (event?: unknown) => void;
  visible?: WritableSignal<boolean>;

  // constructor(config: Partial<IMenuItem>) {
  //   Object.assign(this, config);
  // }
}

export interface IMenu {
  name: string;
  isOpen?: boolean;
  description?: string;
  label?: string;

  items: Array<IMenuItem>;

  // constructor(config: Partial<IMenu>) {
  //   Object.assign(this, config);
  // }
}
